// The code in this file is an adaption from https://github.com/atais/ng2-eonasdan-datetimepicker which we use as the date time picker.
// The date time picker does not support multiple dates, but this date picker does and looks the same.  But I had to write the directive 
// wrapper to support it.

import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    EventEmitter,
    Output,
    forwardRef,
    ChangeDetectorRef,
    KeyValueDiffer,
    KeyValueDiffers,
    DoCheck,
    OnDestroy,
    HostListener
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
declare var $: any;
import 'bootstrap-datepicker';

@Directive({
    selector: '[multidatepicker]',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MultiDatePickerDirective), multi: true}
    ]
})
export class MultiDatePickerDirective implements OnInit, OnDestroy, DoCheck {

    _values: Date[];
    private _options: DatepickerOptions = {};
    @Input() set options(value) {
      if (value !== null) {
        this._options = value;
      }
    }
    get options(): DatepickerOptions {
        return this._options;
    }
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    datepicker: any;
    private dpinitialized: boolean;

    private dpElement;
    private optionsDiffer: KeyValueDiffer<string, any>;
    private _onTouched: any = () => {
    }
    private _onChange: any = () => {
    }

    constructor(
        private changeDetector: ChangeDetectorRef,
        protected el: ElementRef,
        private differs: KeyValueDiffers
    ) {
        this.dpinitialized = false;
        const $parent = $(el.nativeElement.parentNode);
        this.dpElement = $parent.hasClass('input-group') ? $parent : $(el.nativeElement);
    }

    @HostListener('blur') onBlur() {
        this._onTouched();

        // Typing into the box does not set the values event, so on exit, get the value of the dates.
        this.value = this.dpElement.datepicker('getDates');

        // It also seems to retain invalid enteries while still picking a date, so setting the value back to
        // the value it has just read out causes it to update again.
        this.setDpValue(this.value);
    }

    get value() {
        return this._values || null;
    }

    @Input() set value(val) {
        this._values = val;
        this._onChange(this._values);
        if (val) {
            this._onTouched();
        }
        this.changeDetector.markForCheck();
    }

    writeValue(value) {
        if (!value) {
            this._values = null;
        } else {
            this._values = value;
        }
        this.setDpValue(this._values);
    }

    registerOnChange(fn) {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => any): void {
        this._onTouched = fn;
    }

    private setDpValue(val) {
        if (!this.dpinitialized) {
            return;
        }
        if (val) {
            this.dpElement.datepicker('setDates', this._values);
        } else {
            this.dpElement.datepicker('clearDates');
        }
    }
    setDisabledState(isDisabled: boolean): void {
        this.dpElement.children().prop("disabled", isDisabled);
      }

    ngOnInit(): void {
        this.dpinitialized = true;
        this.dpElement.datepicker(this.options);

        this.dpElement.datepicker('setDates', this._values);

        this.dpElement.on('changeDate', (e) => {
            if (e.dates !== this.value) {
                this.value = e.dates || null;
            }
        });

        this.dpElement.on('click', () => this.onClick.emit());
        //this.optionsDiffer = this.differs.find(this.options).create();
    }

    ngDoCheck() {
        //if (this.dpinitialized) {
        //    const changes = this.optionsDiffer.diff(this.options);
        //    if (changes) {
        //        $.map(this.options, (value, key) => {
        //            this.datepicker[key](value);
        //        });
        //    }
        //}
    }

    ngOnDestroy(): void {
        this.dpElement.datepicker('destroy');
    }

}