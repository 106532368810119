import { Component, OnDestroy, Renderer2, OnInit } from '@angular/core';

import pageSettings from '@app/config/page-settings';
//import { ConfigurationService } from '../../_services/configuration.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'error503',
    templateUrl: './error503.component.html'
})

export class Error503Component implements OnInit, OnDestroy {
    pageSettings = pageSettings;
    supportEmail: string;
    supportPhoneNumber: string;

    constructor() {
        this.pageSettings.pageEmpty = true;
    }

    ngOnInit(): void {
        this.supportEmail = environment.contactDetails.supportEmail;
        this.supportPhoneNumber = environment.contactDetails.supportPhoneNumber;
    }

    ngOnDestroy() {
        this.pageSettings.pageEmpty = false;
    }
}
