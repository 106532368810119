import { Component } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

    constructor() { }

    //Select TSR drop down list and selected() method will do something on change of click
    //selectedTSR;
    //data: Array<Object> = [
    //    { id: 0, name: "Please Select TSRZone" },
    //    { id: 1, name: "Zone 1" },
    //    { id: 2, name: "Zone 2" },
    //     {id: 3, name: "Zone 3" }
    //];

    //selected() {
    //    if (this.selectedTSR.id = 0) {
    //        $('#TSRHidden').hide();
    //        console.log(this.selectedTSR);
    //    }
    //    else if (this.selectedTSR.id = 1) {
    //        $('#TSRHidden').show();
    //        console.log(this.selectedTSR);
    //    } else if (this.selectedTSR.id = 2) {
    //        $('#TSRHidden').show();
    //        console.log(this.selectedTSR);
    //    } else if (this.selectedTSR.id = 3) {
    //        $('#TSRHidden').show();
    //        console.log(this.selectedTSR);
    //    }
    //    console.log(this.selectedTSR)
    //}

    //number validation so TSR doesnt exceed 100 or 3 characters
//    numberValidation() {
//        var x, text;
//        // Get the value of the input field with id="numb"
//        x = document.getElementById("TSRInput").innerHTML;

//        // If x is Not a Number or less than one or greater than 10
//        if (isNaN(x) || x < 1 || x > 100) {
//            text = "Input not valid";
//        } else {
//            text = "Input OK";
//        }
//        document.getElementById("TSRCurrentValue").innerHTML = text;
//    }


    //save modal information to update TSR
//    saveTSRinput() {
//    var x = document.getElementById("TSRInput").innerHTML;
//    $("#TSRCurrentValue").text(x);
//    console.log(document.getElementById("TSRCurrentValue").innerHTML);
//}

  //--> open TSR modal on edit click
  //  openTSRModal() {
  //    $('#TSRModal').show();
  //}
}
