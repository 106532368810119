import { Injectable, NgModuleFactory } from '@angular/core';
//import * from '../../../../../dist/polyfills';
import 'zone.js/dist/zone';

@Injectable({
  providedIn: 'root'
})
export abstract class PluginLoaderService {
  protected constructor() {
    this.provideExternals();
  }

  abstract provideExternals(): void;

  abstract load<T>(pluginName, addToHistory): Promise<NgModuleFactory<T>>;

  abstract loadAlias<T>(routeName, addToHistory): Promise<NgModuleFactory<T>>;
}
