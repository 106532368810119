import { Component, Renderer2 } from '@angular/core';
import * as $ from 'jquery';
import { Router, ActivatedRoute } from '@angular/router';
//import pageSettings from '@app/config/page-settings';

@Component({
    selector: 'app-UserManagement',
    templateUrl: './UserManagement.html',
})
export class UserManagement {
    //pageSettings = pageSettings;
    returnUrl: string;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private renderer: Renderer2) {
        //this.pageSettings.pageEmpty = true;
        this.renderer.addClass(document.body, 'bg-white');
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    //EditUserSubmit() { }

}
