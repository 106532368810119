export class ResetPasswordRequest {

    constructor(newPassword: string, username: string, resetToken: string) {
        this.newPassword = newPassword;
        this.username = username;
        this.resetToken = resetToken;
    }

    newPassword: string;
    username: string;
    resetToken: string;
}