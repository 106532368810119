import { Injectable, NgModuleFactory } from '@angular/core';
import { PluginLoaderService } from './plugin-loader.service';
import { PLUGIN_EXTERNALS_MAP } from './plugin-externals';
import { PluginsConfigProvider } from '../plugins-config.provider';
import { PluginData } from '../../_models/plugin-data.model';
import { keycloakService } from '@app/_services/keycloak.service';
import { environment } from '../../../environments/environment';

const SystemJs = window['System']; 

@Injectable({
  providedIn: 'root'
})
export class ClientPluginLoaderService extends PluginLoaderService {

  constructor(private configProvider: PluginsConfigProvider, private keycloakService: keycloakService) {
    super();
    
    console.log("Creating ClientPluginLoaderService");
    this.loadPlugins();
  }

  async loadPlugins() {
    if (this.configProvider != undefined)
    {
      console.log("Loading Plugins");
      let plugins: PluginData[] = [];
      for (let key in this.configProvider.config) {
        console.log(key + ' -> ' + this.configProvider.config[key].name);
        plugins.push(this.configProvider.config[key]);
      }
      console.log('plugins = ' + JSON.stringify(plugins));
      plugins.sort(function (a, b) { return a.loadOrder - b.loadOrder });
      console.log('plugins = ' + JSON.stringify(plugins));
      for (var i = 0; i < plugins.length; i++) {
        console.log(plugins[i].name + ' -> ' + plugins[i].path);
        await this.registerPlugin(plugins[i]);
      }
    }
  }

  async registerPlugin(plugin) {
    console.log('registerPlugin: ' + plugin.moduleName + ' -> ' + plugin.path);
    await SystemJs.import(plugin.path).then(
      exports => {
          window['define'](plugin.moduleName, [], () => exports.default);

          console.log('Imported ' + plugin.path + ' : exports  = ' + JSON.stringify(exports));
          console.log('Registered ' + plugin.path + ' : route = ' + plugin.routePath);
      }
    );
  }

  provideExternals() {
    Object.keys(PLUGIN_EXTERNALS_MAP).forEach(externalKey =>
      window['define'](externalKey, [], () => PLUGIN_EXTERNALS_MAP[externalKey])
    );
  }

  checkPermission(permission: string): boolean {
    console.log('checkPermission: Checking permission "' + permission);
    // eg. "Permissions.CanGetMedia"

    if (!this.keycloakService.isUserInRole(permission)) {
      console.log('checkPermission: Permission "' + permission + '" is denied.');
      return false;
    }

    console.log('checkPermission: Permission "' + permission + '" is allowed.');
    return true;
  }

  loadAlias<T>(aliasName, addToHistory): Promise<NgModuleFactory<T>> {
    console.log("loadAlias " + aliasName);
    if (this.configProvider != undefined) {
      for (let key in this.configProvider.config) {
        if (this.configProvider.config[key].routePath == aliasName) {
          console.log(key + ' -> ' + this.configProvider.config[key].name);
          return this.load(key, addToHistory);
        }
      }
    }
  }

  load<T>(pluginName, addToHistory): Promise<NgModuleFactory<T>> {
    console.log("loadPLUGIN " + pluginName);
    const { config } = this.configProvider;
    if (!config[pluginName]) {
      throw Error(`Can't find appropriate plugin`);
    }

    if (!this.checkPermission(config[pluginName].permission)) {
      throw Error('Insufficient privileges to load plugin ' + pluginName);
    }

      document.title = config[pluginName].name + " | " + environment.applicationTitle;

    const depsPromises = (config[pluginName].deps || []).map(dep => {
      return SystemJs.import(config[dep].path).then(m => {
        window['define'](dep, [], () => m.default);
      });
    });

    return Promise.all(depsPromises).then(() => {
      return SystemJs.import(config[pluginName].path).then(
        module => module.default.default
      );
    });
  }
}
