import { Credentials } from "./credentials";

export class CreateUserRequest {

    constructor(firstName: string, lastName: string, username: string, email: string, groups: string[]) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.username = username;
        this.email = email;
        this.groups = groups;
        this.enabled = true;
    }

    firstName: string;
    lastName: string;
    username: string;
    email: string;
    groups: string[];
    enabled: boolean;
}