import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { preserveServerState } from './transfer-state.service';
import { MenusConfig } from './../_models/menus-config.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class MenusConfigProvider {
  config: MenusConfig;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: {},
    @Inject('APP_BASE_URL') @Optional() private readonly baseUrl: string
  ) {
    if (isPlatformBrowser(platformId)) {
      this.baseUrl = document.location.origin;
    }
  }

  @preserveServerState('MENU_CONFIGS')
  loadConfig() {
      console.log("MenusConfig loadConfig");
      return this.http.get<MenusConfig>(
        `${this.baseUrl}/assets/menus-config.json`
      );
  }
}
