import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { UserService } from '@app/_services';
import pageSettings from '@app/config/page-settings';
import { RequestPasswordResetRequest } from '../../_models/request-password-reset-request';

import { MustMatch } from '@app/_helpers/must-match.validator';
import { PatternValidator } from '@app/_helpers/pattern-validator.validator';
import { ResetPasswordRequest } from '../../_models/reset-password-request';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html'
})

export class ResetPasswordComponent implements OnDestroy {
    pageSettings = pageSettings;
    resetPasswordForm: FormGroup;
    newPassword: ResetPasswordRequest;
    loading = false;
    submitted = false;
    returnUrl: string;
    passwordUpdated = false;
    passwordUpdateFailed = false;
    response = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private renderer: Renderer2
    ) {
        this.pageSettings.pageEmpty = true;
        this.renderer.addClass(document.body, 'bg-white');
    }

    ngOnInit() {
        this.resetPasswordForm = this.formBuilder.group({
            username: ['', Validators.required],
            newPassword: ['', [Validators.required,
            Validators.minLength(8),
            PatternValidator(/\d/, { hasNumber: true }),
            PatternValidator(/[A-Z]/, { hasCapitalCase: true }),
            PatternValidator(/[a-z]/, { hasSmallCase: true }),
            PatternValidator(/[@$!%*#?& -]/, { hasSymbol: true })]],
            confirmPassword: ['', Validators.required]
        }, { validator: MustMatch('newPassword', 'confirmPassword') });

        this.returnUrl = '/';
    }

    ngOnDestroy() {
        this.pageSettings.pageEmpty = false;
        this.renderer.removeClass(document.body, 'bg-white');
    }

    // convenience getter for easy access to form fields
    get form() { return this.resetPasswordForm.controls; }

    formSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.resetPasswordForm.invalid) {
            return;
        }

        this.loading = true;

        this.userService.setNewPassword(this.form.newPassword.value, this.form.username.value, this.route.snapshot.queryParams['t'] || '').subscribe(success => {
            this.response = "Password has been updated successfully!";
            this.resetPasswordForm.reset();
            this.passwordUpdated = true;
            this.passwordUpdateFailed = false;
        },
            error => {
                this.response = "Unable to update password.  Please check the username and try again.";
                this.passwordUpdated = false;
                this.passwordUpdateFailed = true;
        });

        this.loading = false;
    }

    returnToLoginClick() {
        this.passwordUpdated = false;
        this.passwordUpdateFailed = false;
        this.submitted = false;
        this.loading = false;
        this.resetPasswordForm.reset();

        this.router.navigate([this.returnUrl]);
    }
}
