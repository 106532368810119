import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ConfigurationService } from '../../_services';
@Component({
  selector: 'footer',
  templateUrl: './footer.component.html'
})

export class FooterComponent {
    targetSystem;
    targetSystemStatus;
    currentTargetTime;
    constructor(private configurationService: ConfigurationService) {
        this.targetSystem = environment.targetSystem;
        this.targetSystemStatus = "unknown";
        this.getSystemStatus();
        this.getCurrentTime();
    }


    async getSystemStatus() {

        await this.configurationService.getSystemStatus().then((result) => {
            this.targetSystemStatus = result;
        }).catch((err) => {
            this.targetSystemStatus = 'unknown';
        });

        setTimeout(async () =>
            await this.getSystemStatus()
            , environment.statusUpdateDelay);
    }

    async getCurrentTime() {


        await this.configurationService.getTargetTime().then((result) => {
            this.currentTargetTime = result;

        }).catch((err) => {
            this.currentTargetTime = null;
        });

        setTimeout(async () =>
            await this.getCurrentTime(), 1000);
    }
}
