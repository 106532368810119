// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { KeycloakConfig } from "keycloak-angular"

function overrideConfig(key) {
    if (window['config'] != undefined) {
        var keyValue = window['config'][key];
        if (keyValue != undefined) {
            console.log("Config override: " + key + " = " + JSON.stringify(keyValue));
            return keyValue;
        }
    }
    return undefined;
}

//Common:
let keycloakConfig: KeycloakConfig = {
    url: overrideConfig('keycloakUrl') || 'https://login.stg.ketech-pis.com/auth/',
    realm: overrideConfig('keycloakRealm') || 'myrealm',
    clientId: overrideConfig('keycloakClient') || 'myclient',
}

let contactDetailsConfig = {
    supportEmail: overrideConfig('supportEmail') || "support@foo.com",
    supportPhoneNumber: overrideConfig('supportPhoneNumber') || "0123456789"
}

export const environment = {
    production: false,
    keycloak: keycloakConfig,
    contactDetails: contactDetailsConfig,
    logoutUrl: overrideConfig('logoutUrl') || 'https://localhost:5006',
    adminConsoleUrl: overrideConfig('adminConsoleUrl') || '#KEYCLOAKURL#admin/master/console/#/realms/#REALM#/users',
    idleTimeoutSeconds: overrideConfig('idleTimeoutSeconds') || 900,
    cmtApiUrl: overrideConfig('cmtApiUrl') || "https://localhost:5006",
    keyPressTimeoutMs: overrideConfig('keyPressTimeoutMs') || 500,

    targetSystem: overrideConfig('targetSystem') || "KeInform",
    statusUpdateDelay: overrideConfig('statusUpdateDelay') || 5000,

    applicationTitle: overrideConfig('applicationTitle') || "KeTech CMT",
    hubUrl: overrideConfig('hubUrl') || "https://localhost:5006/publishhub",
    rulePluginEnabled: overrideConfig('rulePluginEnabled') || true,

    nameMaxLength: 50,
    emailMaxLength: 50,
    firstNameMaxLength: 50,
    lastNameMaxLength: 50
};
export const SequencingConfig = {
    typeOfMedia: overrideConfig('typeOfMedia') || ["Media", "Message", "Target Page"],
    typeOfFlagsTypes: overrideConfig('typeOfFlagsTypes') || ["Boolean", "Signed", "Un-signed"],
    pageSequenceNameMaxLength: overrideConfig('pageSequenceNameMaxLength') || 50,
    keyPressTimeoutMs: overrideConfig('keyPressTimeoutMs') || 500,
    maxNumberOfMediaSets: overrideConfig('maxNumberOfMediaSets') || 20,
    defaultNewSequenceId: overrideConfig('defaultNewSequenceId') || 10000,
    defaultPageDisplayDuration: overrideConfig('defaultPageDisplayDuration') || 15,
    mandatorySequence: overrideConfig('mandatorySequence') || [{
        name: "KI500v2 Timed Media",
        displayDuration: 10,
        enabled: true,
        pageType: "Media",
        repeatTime: 0,
        sequencePosition: 1,
        associatedData: "0d05e221-0034-4e55-7cf4-08d9b8ac65f6",
        isReadOnly: true,
        isDeleted: false
    }, {
        name: "Default Page",
        displayDuration: 10,
        enabled: true,
        pageType: "Default",
        repeatTime: 0,
        sequencePosition: 0,
        isReadOnly: false,
        isDeleted: false
    }]
}
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
