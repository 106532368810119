import { PipeTransform, Pipe } from "@angular/core";
import { min } from "rxjs/operators";

@Pipe({
    name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {

    transform(value: number): string {
        let minutes: number = Math.trunc(value / 60);

        // If the value is negative and less than 60 seconds, multiply by -1 so it shows as negative.
        if (minutes === 0 && value < 0) {
            minutes = minutes * -1;
        }

        const seconds: number = Math.abs(value - minutes * 60);

        return minutes + ':' + seconds.toString().padStart(2, '0');
    }

}