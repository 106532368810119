import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, ValidationErrors, AbstractControl } from '@angular/forms';
import { UserService } from '@app/_services';
import { MustMatch } from '@app/_helpers/must-match.validator';
import { PatternValidator } from '@app/_helpers/pattern-validator.validator';
import { PasswordUpdateRequest } from '@app/_models/password-update-request';
import { keycloakService } from '../../_services/keycloak.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent {

    passwordForm: FormGroup;
    passwordFormDir: FormGroupDirective;
    passwordUpdate: PasswordUpdateRequest

    submitted = false;
    loading = false;
    updateSucceeded = false;
    updateFailed = false;

    response = '';


    constructor(private formBuilder: FormBuilder, private userService: UserService, private keycloakService: keycloakService, private router: Router) {
    }

    ngOnInit() {
        this.passwordForm = this.formBuilder.group({
            currentPassword: ['', {validators: Validators.required, asyncValidators: this.validatePassword.bind(this), updateOn: 'blur' }],
            newPassword: ['', [Validators.required,
                Validators.minLength(12),
                PatternValidator(/\d/, { hasNumber: true }),
                PatternValidator(/[A-Z]/, { hasCapitalCase: true }),
                PatternValidator(/[a-z]/, { hasSmallCase: true }),
                PatternValidator(/[@$!%*#?& -]/, { hasSymbol: true})]],
            confirmPassword: ['', Validators.required]
        }, { validator: [MustMatch('newPassword', 'confirmPassword')] });
    }

    validatePassword(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return this.userService.checkPassword(this.keycloakService.getUsername(),ctrl.value).pipe(
            map(isValid => {
                console.log('success');
                return null;
            }),
            catchError(() => {
                console.log('error');
                return of({ incorrectPasswordEntered: true } )
            })
        );
    }

    // convenience getter for easy access to form fields
    get form() { return this.passwordForm.controls; }

    formSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.passwordForm.invalid) {
            this.updateSucceeded = false;
            this.updateFailed = true;
            return;
        }

        this.loading = true;

        var userId = this.keycloakService.getKeycloakInstance().tokenParsed.sub;
        this.userService.updatePassword(userId, this.form.confirmPassword.value).subscribe(success => {
            this.response = "Password has been updated successfully!";
            this.passwordForm.reset();
            this.submitted = false;
            this.updateSucceeded = true;
            this.updateFailed = false;
        },
        error =>
        {
            this.response = error.error;
            this.updateSucceeded = false;
            this.updateFailed = true;
            this.loading = false;
        });        
    }

    returnToMainPage() {
        $('#backButton').click();
    }
}