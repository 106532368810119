import { BrowserModule, Title } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule, DoBootstrap, ApplicationRef } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCheckboxModule } from "@angular/material/checkbox"
// Page components.
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SidebarRightComponent } from "./components/sidebar-right/sidebar-right.component";
import { TopMenuComponent } from "./components/top-menu/top-menu.component";
import { FooterComponent } from "./components/footer/footer.component";
import { PanelComponent } from "./components/panel/panel.component";
import { FloatSubMenuComponent } from "./components/float-sub-menu/float-sub-menu.component";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { PasswordStrengthMeterModule } from "angular-password-strength-meter";
import { NotifierModule } from "angular-notifier";
import { MultiDatePicker } from "./components/multi-date-picker/multi-date-picker.module";
import { ApproveConfirmComponent } from "shared";
import { LoadingComponent } from "shared";

// External components.
import { TrendModule } from "ngx-trend";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { NgxDatatableModule, ColumnMode } from "@swimlane/ngx-datatable";
import { Ng2TableModule } from "ngx-datatable/ng2-table";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { NgxBootstrapSwitchModule } from "ngx-bootstrap-switch";
import { ModalModule } from "ngb-modal";
import { MultiselectDropdownModule } from "angular-2-dropdown-multiselect";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { KeycloakAngularModule } from 'keycloak-angular';
import { DragDropModule } from "@angular/cdk/drag-drop";

// Pages.
import { HomeComponent } from "./pages/home/home.component";
import { ErrorComponent } from "./pages/error/error.component";
import { Error503Component } from "./pages/error503/error503.component";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { LoginV2Component } from "./pages/login-v2/login-v2.component";
import { LoginV3Component } from "./pages/login-v3/login-v3.component";
import { UserManagement } from "./pages/UserManagement/UserManagement";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { UsersComponent } from "./pages/users/users.component";
import { PageSequencingComponent } from "./pages/page-sequencing/page-sequencing.component";

import { PluginLoaderService } from './_services/plugin-loader/plugin-loader.service';
import { ClientPluginLoaderService } from './_services/plugin-loader/client-plugin-loader.service';
import { PluginsConfigProvider } from './_services/plugins-config.provider';
import { MenusConfigProvider } from './_services/menus-config.provider';
import { MenuLoaderService } from './_services/menu-loader.service';

// Authentication based helpers.
import {
    JwtInterceptor,
    MinuteSecondsPipe,
} from "./_helpers";

import { CookieService } from "ngx-cookie-service";
import { environment } from "../environments/environment";
import { keycloakService } from "@app/_services/keycloak.service";

const keycloakservice = new keycloakService();

@NgModule({
  declarations: [
    // Page components.
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    PanelComponent,
    FloatSubMenuComponent,
    ApproveConfirmComponent,
    LoadingComponent,

    // Pages.
    HomeComponent,
    ErrorComponent,
    Error503Component,
    ForbiddenComponent,
    LoginV2Component,
    LoginV3Component,
    UserManagement,
    ResetPasswordComponent,
    ChangePasswordComponent,
    UsersComponent,
    PageSequencingComponent,


    // Custom pipe
    MinuteSecondsPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    KeycloakAngularModule,
    FormsModule,
    ReactiveFormsModule,
      LoadingBarRouterModule,
      MatCheckboxModule,
    PasswordStrengthMeterModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: "right",
          distance: 12
        },
        vertical: {
          position: "bottom",
          distance: 10,
          gap: 10
        }
      },
      theme: "material",
      behaviour: {
        autoHide: 5000,
        onClick: false,
        onMouseover: "pauseAutoHide",
        showDismissButton: true,
        stacking: 4
      },
      animations: {
        enabled: true,
        show: {
          preset: "slide",
          speed: 300,
          easing: "ease"
        },
        hide: {
          preset: "fade",
          speed: 300,
          easing: "ease",
          offset: 50
        },
        shift: {
          speed: 300,
          easing: "ease"
        },
        overlap: 150
      }
    }),
    PerfectScrollbarModule,
    TrendModule,
    NgbModule,
    NgxDatatableModule,
    Ng2TableModule,
    JwBootstrapSwitchNg2Module,
    NgxBootstrapSwitchModule.forRoot(),
    BrowserAnimationsModule,
    ModalModule,
    MultiDatePicker,
    MultiselectDropdownModule,
    NgxChartsModule,
    NgxUiLoaderModule,
    DragDropModule,
    RouterModule.forRoot([]),
  ],
  exports: [
      LoadingComponent
  ],
  providers: [
    PluginsConfigProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: (provider: PluginsConfigProvider) => () =>
        provider
          .loadConfig()
          .toPromise()
          .then(config => (provider.config = config)),
      multi: true,
      deps: [PluginsConfigProvider]
    },
    { provide: keycloakService, useValue: keycloakservice },
      { provide: PluginLoaderService, useClass: ClientPluginLoaderService },

      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

    CookieService,
    MenuLoaderService,
    MenusConfigProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: (provider: MenusConfigProvider) => () =>
        provider
          .loadConfig()
          .toPromise()
          .then(config => (provider.config = config)),
      multi: true,
      deps: [MenusConfigProvider]
    },
    HttpClientModule
    ],
    entryComponents: [AppComponent, LoginV3Component, ChangePasswordComponent, PageSequencingComponent, UsersComponent]
})
export class AppModule implements DoBootstrap {

    async ngDoBootstrap(appRef: ApplicationRef) {
        const keycloakConfig = environment.keycloak;
        try
        {
            console.log('[ngDoBootstrap] init keycloak');
            await keycloakservice
                .init({
                    config: keycloakConfig,
                    initOptions: {
                        onLoad: 'check-sso',
                        checkLoginIframe: false,
                    },
                    enableBearerInterceptor: true,
                });
        }
        catch (error)
        {
            console.error('Keycloak init failed', error);
            alert("Keycloak is down");
        }

        try
        {
            appRef.bootstrap(AppComponent);
        }
        catch (error)
        {
            console.log('[ngDoBootstrap] bootstrap app');
            console.error('App Bootstrap failed', error);
        }
    }
}
