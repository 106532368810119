import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '../_models/user';
import { CookieService } from 'ngx-cookie-service';
import { UserFromTokenRequest } from '../_models/user-from-token-request';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private cookieService: CookieService) {
        this.currentUserSubject = new BehaviorSubject<User>(null);
        this.currentUser = this.currentUserSubject.asObservable();
    }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  login(username: string, password: string, rememberMe: boolean) {
    return this.http.post<any>(`${environment.cmtApiUrl}/api/users/authenticate`, { username, password })
        .pipe(map(user => {
            // Delete cookies incase the user has accidentally logged in twice from the home page creating multiple cookies
            this.cookieService.deleteAll();

        // Set the token to have an expiry time limit which makes it a permanent cookie rather than a session cookie
          if (rememberMe) {
              this.cookieService.set('UISWebUIToken', user.token, 7);
          }
          else {
              this.cookieService.set('UISWebUIToken', user.token);
          }

        this.currentUserSubject.next(user);

        return user;
      }));
  }

  logout() {
      this.cookieService.deleteAll();
      this.currentUserSubject.next(null);
    }

    hasPermission(permission: string): boolean {
        if (this.currentUserValue && typeof this.currentUserValue.accessPermissions !== 'undefined' && this.currentUserValue.accessPermissions.length > 0) {
            if (this.currentUserValue.accessPermissions.includes(permission)) {
                return true;
            }
            else {
                return false;
            }
        }   
        return false; 
    }

    getUserDetails() {
        var token = this.getTokenCookie();

        if (token) {
            return this.http.get<any>(`${environment.cmtApiUrl}/api/users/current`)
                .pipe(map(user => {

                    this.currentUserSubject.next(user);

                    return user;
                }));
        }      
    }

    hasTokenCookie() {
        if (this.cookieService.check('UISWebUIToken')) {
            return true;
        }
        return false;
    }

    getTokenCookie() {
        return this.cookieService.get('UISWebUIToken');
    }

    deleteAllCookies() {
        this.cookieService.deleteAll();
    }
}
