var pageMenus = [
    // Need to add to these dynamically from plugins-menu.json
    //{
    //  icon: "fas fa-image",
    //  title: "Media",
    //  url: "media",
    //  caret: true,
    //  permission: "Permissions.CanGetMedia",
    //  visible: true
    //},
    {
        icon: "fas fa-cog",
        title: "Settings",
        url: "",
        caret: true,
        visible: true,
        permission: "",
        submenu: [
            {
              title: "Change Password",
              url: "changepassword",
              caret: false,
              permission: "Permissions.CmtUser",
              visible: false,
              submenu: []
            }

            // Need to add to this dynamically from Administration bit of plugins-menu.json
            //{
            //  title: "Reports",
            //  url: "",
            //  caret: "true",
            //  permission: "",
            //  visible: false,
            //}
        ]
    },
    {
        icon: "fas fa-images",
        title: "Page Sequencing",
        url: "pagesequence",
        caret: false,
        permission: "Permissions.CanGetPageSequences",
        visible: true
    },
];

export default pageMenus;
