import { Component, HostListener, Renderer2, OnInit, ViewChild, ViewContainerRef, Injector, ComponentFactoryResolver } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import pageSettings from '@app/config/page-settings';
import * as global from '@app/config/globals';
import { Subject } from 'rxjs';
import { keycloakService } from '@app/_services/keycloak.service';
import { environment } from '../environments/environment';
import { PluginLoaderService } from './_services/plugin-loader/plugin-loader.service';
import { MenuLoaderService } from './_services/menu-loader.service';
import { LoginV3Component } from './pages/login-v3/login-v3.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component'
import { PageSequencingComponent } from './pages/page-sequencing/page-sequencing.component';
import { UsersComponent } from './pages/users/users.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {
  @ViewChild('targetRef', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef;
    @ViewChild(SidebarComponent, { static: false }) sidebar: SidebarComponent;

pageSettings;
userActivity;

  private logoutUrl: string;
  private idleTimeoutSeconds: number;

  constructor(
              private keycloakService: keycloakService,
              private injector: Injector, 
              private pluginLoader: PluginLoaderService, private menuLoader: MenuLoaderService,
              private coreComponentFactoryResolver: ComponentFactoryResolver) {
    console.log("Creating AppComponent");
  }

  async ngOnInit() {
    // page settings
    this.pageSettings = pageSettings;

    this.pageSettings.pageMegaMenu = true;
    this.pageSettings.fixed

    this.logoutUrl = environment.logoutUrl;
    this.idleTimeoutSeconds = environment.idleTimeoutSeconds;

    this.setIdleTimer();

    document.title = "Home | " + environment.applicationTitle

    if (this.keycloakService != undefined) {
        var currentUser = this.keycloakService.currentUserValue;
        if (currentUser) {
            console.log("LOGGED IN");
            if (currentUser.token != undefined) {
                console.log("Keycloak token = " + currentUser.token);
                sessionStorage.setItem("keycloakToken", currentUser.token);
                //Crew App redirect
                if (currentUser.accessPermissions.find(x => x == 'Permissions.CanSendMessage') && !currentUser.accessPermissions.find(x => x == 'Permissions.CanCreateMessage')) {
                    this.loadAlias('messaging', false);
                }
            }
        }
        else {
            console.log("NOT LOGGED IN");
            this.loadPlugin("login", false);
        }
    }
    else {
        console.log("NO KEYCLOAK");
    }
  }

  loadAlias(aliasName: string, addToHistory: boolean) {
    console.log("loading alias " + aliasName);
    if (aliasName == '') {
      this.clearPlugin();
    }
    else if (aliasName == 'changepassword') {

        console.log("LOAD CHANGE PASSWORD COMPONENT");
        const compFactory = this.coreComponentFactoryResolver.resolveComponentFactory(ChangePasswordComponent);
        this.clearPlugin();
        this.vcRef.createComponent(compFactory);

        $('.menu-item').removeClass("active");
        var menuItemSelector = '.menu-item[data-menuitem="' + aliasName + '"]';
        $(menuItemSelector).addClass("active");
        $(menuItemSelector)
            .parent().parent().parent().parent()
            .children("a[data-menuitem]")
            .addClass("active");
        $(menuItemSelector)
            .parent().parent().parent().parent()
            .parent().parent().parent().parent()
            .children("a[data-menuitem]")
            .addClass("active");
        $(menuItemSelector)
            .parent().parent().parent().parent()
            .parent().parent().parent().parent()
            .parent().parent().parent().parent()
            .children("a[data-menuitem]")
            .addClass("active");
    }
    else if (aliasName == 'users') {

        console.log("LOAD USERS COMPONENT");
        const compFactory = this.coreComponentFactoryResolver.resolveComponentFactory(UsersComponent);
        this.clearPlugin();
        this.vcRef.createComponent(compFactory);

        $('.menu-item').removeClass("active");
        var menuItemSelector = '.menu-item[data-menuitem="' + aliasName + '"]';
        $(menuItemSelector).addClass("active");
        $(menuItemSelector)
            .parent().parent().parent().parent()
            .children("a[data-menuitem]")
            .addClass("active");
        $(menuItemSelector)
            .parent().parent().parent().parent()
            .parent().parent().parent().parent()
            .children("a[data-menuitem]")
            .addClass("active");
        $(menuItemSelector)
            .parent().parent().parent().parent()
            .parent().parent().parent().parent()
            .parent().parent().parent().parent()
            .children("a[data-menuitem]")
            .addClass("active");
    }
    else if (aliasName == 'pagesequence') {
        console.log("LOAD PAGE SEQUENCE COMPONENT");
        const compFactory = this.coreComponentFactoryResolver.resolveComponentFactory(PageSequencingComponent);
        this.clearPlugin();
        this.vcRef.createComponent(compFactory);

        $('.menu-item').removeClass("active");
        var menuItemSelector = '.menu-item[data-menuitem="' + aliasName + '"]';
        $(menuItemSelector).addClass("active");
        $(menuItemSelector)
            .parent().parent().parent().parent()
            .children("a[data-menuitem]")
            .addClass("active");
        $(menuItemSelector)
            .parent().parent().parent().parent()
            .parent().parent().parent().parent()
            .children("a[data-menuitem]")
            .addClass("active");
        $(menuItemSelector)
            .parent().parent().parent().parent()
            .parent().parent().parent().parent()
            .parent().parent().parent().parent()
            .children("a[data-menuitem]")
            .addClass("active");
    }
    else {
      var xAddToHistory = addToHistory;
      if (xAddToHistory == undefined) {
        xAddToHistory = false;
      }
      this.pluginLoader.loadAlias(aliasName, xAddToHistory).then(moduleFactory => this.changePlugin(moduleFactory));

      $('.menu-item').removeClass("active");
      var menuItemSelector = '.menu-item[data-menuitem="' + aliasName + '"]';
      $(menuItemSelector).addClass("active");
      $(menuItemSelector)
        .parent().parent().parent().parent()
        .children("a[data-menuitem]")
        .addClass("active");
      $(menuItemSelector)
        .parent().parent().parent().parent()
        .parent().parent().parent().parent()
        .children("a[data-menuitem]")
        .addClass("active");
      $(menuItemSelector)
        .parent().parent().parent().parent()
        .parent().parent().parent().parent()
        .parent().parent().parent().parent()
        .children("a[data-menuitem]")
        .addClass("active");
    }
  }

  loadPlugin(pluginName: string, addToHistory: boolean) {
    if (pluginName == "login") {
        console.log("LOAD LOGIN COMPONENT");
        const compFactory = this.coreComponentFactoryResolver.resolveComponentFactory(LoginV3Component);
        this.vcRef.createComponent(compFactory);
    }
    else {
        this.pluginLoader.load(pluginName, addToHistory).then(moduleFactory => this.changePlugin(moduleFactory));
    }
  }

  clearPlugin() {
    this.vcRef.clear();
  }

  changePlugin(moduleFactory) {  //NgModuleFactory
    console.log("changePlugin");
    console.log(this.injector);
    const moduleRef = moduleFactory.create(this.injector);  // --> NgModuleRef
    const entryComponent = (moduleFactory.moduleType as any).entry;
    const compFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(entryComponent);
    this.clearPlugin();
    console.log(compFactory);
    this.vcRef.createComponent(compFactory);
  }

  // window scroll
  pageHasScroll;
    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 0) {
            this.pageHasScroll = true;
        } else {
            this.pageHasScroll = false;
        }
        this.setIdleTimer();
    }

    //key-press
    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        this.setIdleTimer();
    }

    //mouse clicks
    @HostListener('window:click', ['$event'])
    onClick(btn) {
        this.setIdleTimer();
    }

  // hide mobile sidebar
  onNavigate(alias: string): void {
    console.log("USER NAVIGATE: " + alias);
    this.loadAlias(alias, false);
  }

  // set page minified
  onToggleSidebarMinified(val: boolean): void {
    if (this.pageSettings.pageSidebarMinified) {
      this.pageSettings.pageSidebarMinified = false;
    } else {
      this.pageSettings.pageSidebarMinified = true;
    }
  }

  // set page right collapse
  onToggleSidebarRight(val: boolean): void {
    if (this.pageSettings.pageSidebarRightCollapsed) {
      this.pageSettings.pageSidebarRightCollapsed = false;
    } else {
      this.pageSettings.pageSidebarRightCollapsed = true;
    }
  }

  // hide mobile sidebar
  onHideMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      if (this.pageSettings.pageMobileSidebarFirstClicked) {
        this.pageSettings.pageMobileSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileSidebarToggled = false;
      }
    }
  }

  // toggle mobile sidebar
  onToggleMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      this.pageSettings.pageMobileSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileSidebarToggled = true;
      this.pageSettings.pageMobileSidebarFirstClicked = true;
    }
  }


  // hide right mobile sidebar
  onHideMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
        this.pageSettings.pageMobileRightSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileRightSidebarToggled = false;
      }
    }
  }

  // toggle right mobile sidebar
  onToggleMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      this.pageSettings.pageMobileRightSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileRightSidebarToggled = true;
      this.pageSettings.pageMobileRightSidebarFirstClicked = true;
    }
    }

    //Idle timer
    setIdleTimer(): void {
        if (this.userActivity != undefined) {
            clearTimeout(this.userActivity);
        }
        if (this.keycloakService.getKeycloakInstance().authenticated) {
            this.userActivity = window.setTimeout(() =>
                this.keycloakService.logout(this.logoutUrl)
                , this.idleTimeoutSeconds * 1000);
        }
    }

}
