import { Component, OnDestroy, Renderer2, OnInit } from '@angular/core';

import pageSettings from '@app/config/page-settings';
//import { ConfigurationService } from '../../_services/configuration.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'error',
    templateUrl: './error.component.html'
})

export class ErrorComponent implements OnInit, OnDestroy {
    pageSettings = pageSettings;
    supportEmail: string;
    supportPhoneNumber: string;

    //constructor(private configurationService: ConfigurationService) {
    constructor() {
        this.pageSettings.pageEmpty = true;
    }

    ngOnInit(): void {
        //this.configurationService.getConfiguration().subscribe(config => {
        //    this.supportEmail = config.contactDetails.supportEmail;
        //    this.supportPhoneNumber = config.contactDetails.supportPhoneNumber;
        //});
        this.supportEmail = environment.contactDetails.supportEmail;
        this.supportPhoneNumber = environment.contactDetails.supportPhoneNumber;
    }

    ngOnDestroy() {
        this.pageSettings.pageEmpty = false;
    }
}
