import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { environment } from '@environments/environment';
import { UserDetails } from '@app/_models/user-details';
import { CreateUserRequest } from '@app/_models/create-user-request';
import { EditUserRequest } from '@app/_models/edit-user-request';
import { PasswordUpdateRequest } from '@app/_models/password-update-request';
import { RequestPasswordResetRequest } from '../_models/request-password-reset-request';
import { ResetPasswordRequest } from '../_models/reset-password-request';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Role } from '@app/_models/role';
import { ServiceResultUser } from '../_models/service-result-user';
import { Group } from '../_models/group';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    async getAll(): Promise<UserDetails[]> {
        return await this.http.get<UserDetails[]>(`${environment.keycloak.url}admin/realms/${environment.keycloak.realm}/users`).toPromise();
    }

    getUserRoles() {
        let R: Role[] = [];
        return of(R)
    }

    async getUser(id: string) {
        return this.http.get<UserDetails>(`${environment.keycloak.url}admin/realms/${environment.keycloak.realm}/users/${id}`).toPromise();
    }

    async getGroups() {
        return this.http.get<Group[]>(`${environment.keycloak.url}admin/realms/${environment.keycloak.realm}/groups`).toPromise();
    }

    async getUserGroup(id: string) {
        return this.http.get<Group[]>(`${environment.keycloak.url}admin/realms/${environment.keycloak.realm}/users/${id}/groups`).toPromise();
    }

    async updateUserGroup(userId: string, oldGroupId: string, newGroupId: string) {
        let result: ServiceResultUser = new ServiceResultUser();
        result.success = true;
        result.errorMessage = '';

        var statusCode = await this.http.delete<any>(`${environment.keycloak.url}admin/realms/${environment.keycloak.realm}/users/${userId}/groups/${oldGroupId}`, { observe: 'response' })
            .pipe(
                map((res) => {
                    return res.status
                }),
                catchError(error => {
                    if (error.error instanceof ErrorEvent) {
                        result.errorMessage = error.error.message;
                        console.log("saveUser: Error: " + error.error.message);
                    } else {
                        // error.message gives us back back 'Http failure response for https://localhost:5001/media/upload: 415 OK' or similar from the server,
                        // whatever we try to send back as error _text_ from there, which is confusing as best!
                        result.errorMessage = error.status;

                        console.log("saveUser: Error - " + error.status + " : " + error.message);
                    }
                    return of(error.status);
                })
        ).toPromise();

        if (statusCode == 204) {
            statusCode = await this.http.put<any>(`${environment.keycloak.url}admin/realms/${environment.keycloak.realm}/users/${userId}/groups/${newGroupId}`, null, { observe: 'response' })
                .pipe(
                    map((res) => {
                        return res.status
                    }),
                    catchError(error => {
                        if (error.error instanceof ErrorEvent) {
                            result.errorMessage = error.error.message;
                            console.log("saveUser: Error: " + error.error.message);
                        } else {
                            // error.message gives us back back 'Http failure response for https://localhost:5001/media/upload: 415 OK' or similar from the server,
                            // whatever we try to send back as error _text_ from there, which is confusing as best!
                            result.errorMessage = error.status;

                            console.log("saveUser: Error - " + error.status + " : " + error.message);
                        }
                        return of(error.status);
                    })
                ).toPromise();
        }

        if (statusCode != 204) {
            result.success = false;
        }

        return result;
    }

    async createNewUser(user: UserDetails): Promise<ServiceResultUser> {
        let result: ServiceResultUser = new ServiceResultUser();
        result.success = true;
        result.errorMessage = '';

        var statusCode = await this.http.post<any>(`${environment.keycloak.url}admin/realms/${environment.keycloak.realm}/users`, new CreateUserRequest(user.firstName, user.lastName, user.username, user.email, [user.accessLevel]), { observe: 'response' })
            .pipe(
                map((res) => {
                    return res.status
                }),
                catchError(error => {
                    if (error.error instanceof ErrorEvent) {
                        result.errorMessage = error.error.message;
                        console.log("saveUser: Error: " + error.error.message);
                    } else {
                        // error.message gives us back back 'Http failure response for https://localhost:5001/media/upload: 415 OK' or similar from the server,
                        // whatever we try to send back as error _text_ from there, which is confusing as best!
                        result.errorMessage = error.status;

                        console.log("saveUser: Error - " + error.status + " : " + error.message);
                    }
                    return of(error.status);
                })
        ).toPromise();

        if (statusCode != 201) {
            result.success = false;
        }

        return result;
    }

    async editUser(user: UserDetails): Promise<ServiceResultUser> {
        let result: ServiceResultUser = new ServiceResultUser();
        result.success = true;
        result.errorMessage = '';

        var statusCode = await this.http.put<any>(`${environment.keycloak.url}admin/realms/${environment.keycloak.realm}/users/${user.id}`, new CreateUserRequest(user.firstName, user.lastName, user.username, user.email, [user.accessLevel]), { observe: 'response' })
            .pipe(
                map((res) => {
                    return res.status
                }),
                catchError(error => {
                    if (error.error instanceof ErrorEvent) {
                        result.errorMessage = error.error.message;
                        console.log("editUser: Error: " + error.error.message);
                    } else {
                        // error.message gives us back back 'Http failure response for https://localhost:5001/media/upload: 415 OK' or similar from the server,
                        // whatever we try to send back as error _text_ from there, which is confusing as best!
                        result.errorMessage = error.status;

                        console.log("editUser: Error - " + error.status + " : " + error.message);
                    }
                    return of(error.status);
                })
        ).toPromise();

        if (statusCode != 204) {
            result.success = false;
        }

        return result;
    }

    async deleteUser(userId: string) {
        let result: ServiceResultUser = new ServiceResultUser();
        result.success = true;
        result.errorMessage = '';

        var statusCode = await this.http.delete<boolean>(`${environment.keycloak.url}admin/realms/${environment.keycloak.realm}/users/${userId}`,{ observe: 'response' })
            .pipe(
                map((res) => {
                    return res.status
                }),
                catchError(error => {
                    if (error.error instanceof ErrorEvent) {
                        result.errorMessage = error.error.message;
                        console.log("deleteUser: Error: " + error.error.message);
                    } else {
                        // error.message gives us back back 'Http failure response for https://localhost:5001/media/upload: 415 OK' or similar from the server,
                        // whatever we try to send back as error _text_ from there, which is confusing as best!
                        result.errorMessage = error.status;

                        console.log("deleteUser: Error - " + error.status + " : " + error.message);
                    }
                    return of(error.status);
                })
        ).toPromise();

        if (statusCode != 204) {
            result.success = false;
        }

        return result;
    }

    updatePassword(userId: string, newPassword: string) {
        return this.http.put<PasswordUpdateRequest>(`${environment.keycloak.url}admin/realms/${environment.keycloak.realm}/users/${userId}/reset-password`, { "type": "password", "temporary": false, "value": newPassword });
    }

    //Checks the users password by attempting to retrieve the users token
    checkPassword(username: string, password: string) {
        let body = new URLSearchParams();
        body.set('client_id', environment.keycloak.clientId);
        body.set('username', username);
        body.set('password', password);
        body.set('grant_type', 'password');

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        }

        return this.http.post<any>(`${environment.keycloak.url}realms/${environment.keycloak.realm}/protocol/openid-connect/token`, body.toString(), options);
    }

    sendResetPasswordRequest(email: string) {
        return this.http.post<RequestPasswordResetRequest>(`${environment.cmtApiUrl}/api/users/passwordresetrequest`, new RequestPasswordResetRequest(email));
    }

    setNewPassword(newPassword: string, username: string, resetToken: string) {
        return this.http.post<ResetPasswordRequest>(`${environment.cmtApiUrl}/api/users/setnewpassword`, new ResetPasswordRequest(newPassword, username, resetToken));
    }
}