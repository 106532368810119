import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MultiDatePickerDirective } from './multi-date-picker.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        MultiDatePickerDirective
    ],
    exports: [MultiDatePickerDirective]
})
export class MultiDatePicker {
}