import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { KeycloakAngularModule} from 'keycloak-angular';

import { UserService } from '@app/_services';
import pageSettings from '@app/config/page-settings';
import { RequestPasswordResetRequest } from '../../_models/request-password-reset-request';
import { keycloakService } from '@app/_services/keycloak.service';

@Component({
    selector: 'login-v3',
    templateUrl: './login-v3.component.html'
})

export class LoginV3Component implements OnDestroy {
    pageSettings = pageSettings;
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    passwordResetRequest: RequestPasswordResetRequest;
    emailFormGroup: FormGroup;
    forgotPassword = false;
    emailSubmitted = false;
    linkSent = false;
    emailResetRequestLoading = false;
    emailResetResponse = '';
    invalidEmail = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private keycloakService: keycloakService,
        private userService: UserService,
        private renderer: Renderer2
    ) {
        this.pageSettings.pageEmpty = true;
        this.pageSettings.pageWithFooter = false;
        this.renderer.addClass(document.body, 'bg-white');
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            rememberMe: ['']
        });

        this.emailFormGroup = this.formBuilder.group({
            email: ['', Validators.email]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    ngOnDestroy() {
        this.pageSettings.pageEmpty = false;
        this.renderer.removeClass(document.body, 'bg-white');
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    get emailForm() { return this.emailFormGroup.controls; }

    formSubmit() {
        this.submitted = true;
        this.loading = true;

        this.keycloakService.login();
    }

    forgotPasswordClick() {
        this.forgotPassword = true;
    }

    returnToLoginClick() {
        this.forgotPassword = false;
        this.emailSubmitted = false;
        this.linkSent = false;
        this.emailResetRequestLoading = false;
        this.invalidEmail = false;
        this.emailResetResponse = '';
        this.emailFormGroup.reset();
    }

    emailFormSubmit() {

        this.emailSubmitted = true;

        if (this.emailFormGroup.invalid) {
            this.invalidEmail = true;
            return;
        }

        this.invalidEmail = false;
        this.emailResetRequestLoading = true;

        this.userService.sendResetPasswordRequest(this.emailForm.email.value).subscribe(result => {            
            this.linkSent = true;
            this.emailResetResponse = "Thank you for entering your email address.  Please check your emails for instructions on what to do next.";
        }, error => {
            this.linkSent = false;
                this.emailResetResponse = "Unknown error.  Unable to send password reset link.";
                this.emailResetRequestLoading = false;
        });

    }
}
