import { Component, OnDestroy, Renderer2 } from '@angular/core';

import pageSettings from '@app/config/page-settings';

@Component({
    selector: 'forbidden',
    templateUrl: './forbidden.component.html'
})

export class ForbiddenComponent implements OnDestroy {
    pageSettings = pageSettings;

    constructor() {
        this.pageSettings.pageEmpty = true;
    }

    ngOnDestroy() {
        this.pageSettings.pageEmpty = false;
    }
}
