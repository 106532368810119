import { Component, Input, Output, EventEmitter, Renderer2, OnDestroy } from '@angular/core';
import pageSettings from '../../config/page-settings';
import { User } from '@app/_models/user';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Key } from 'protractor';
import { keycloakService } from '@app/_services/keycloak.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnDestroy {
  @Input() pageSidebarTwo;
  @Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
  @Output() toggleMobileSidebar = new EventEmitter<boolean>();
  @Output() toggleMobileRightSidebar = new EventEmitter<boolean>();
  @Output() navigate = new EventEmitter<string>();
  pageSettings = pageSettings;
  currentUser: User;
  canCreateUser: boolean;
  canChangePassword: boolean;

  mobileSidebarToggle() {
    this.toggleMobileSidebar.emit(true);
  }
  mobileRightSidebarToggle() {
    this.toggleMobileRightSidebar.emit(true);
  }
  toggleSidebarRight() {
    this.toggleSidebarRightCollapsed.emit(true);
  }

  mobileTopMenuToggle() {
    this.pageSettings.pageMobileTopMenuToggled = !this.pageSettings.pageMobileTopMenuToggled;
  }

  mobileMegaMenuToggle() {
    this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings.pageMobileMegaMenuToggled;
  }

  ngOnDestroy() {
    this.pageSettings.pageMobileTopMenuToggled = false;
    this.pageSettings.pageMobileMegaMenuToggled = false;
  }

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private keycloakService: keycloakService) {
      this.canChangePassword = this.keycloakService.hasPermission('Permissions.CanChangePassword');
      this.canCreateUser = this.keycloakService.hasPermission('Permissions.CanCreateUser'); 
    }

    goToChangePasswordPage() {
        this.navigate.emit("changepassword");
    }

    goToUsersPage() {
        this.navigate.emit("users");
    }

    closePlugin() {
        $('#backButton').click();
    }

    logout() {
    
        try {
             this.keycloakService.logout();
               
        }
        catch (error) {
            console.error('Keycloak init failed', error);
        }
  }
}
