import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { ConfigurationCollection } from '../_models/configuration-collection';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
    constructor(private http: HttpClient) { }

    getConfiguration() {
        return this.http.get<ConfigurationCollection>(`${environment.cmtApiUrl}/api/media/configuration`);
    }

    async getSystemStatus() : Promise<string> {

        return await this.http.get(`${environment.cmtApiUrl}/api/comms/status`, { responseType: 'text' }).toPromise();
     
    }

    async getTargetTime(): Promise<string> {

        //return Date();
        return await this.http.get(`${environment.cmtApiUrl}/api/comms/targetTime`, { responseType: 'text' }).toPromise();

    }
}