import { Injectable, NgModuleFactory } from '@angular/core';
//import { MENU_EXTERNALS_MAP } from '../plugin-externals';
import { MenusConfigProvider } from './menus-config.provider';
import { MenuData } from '../_models/menu-data.model';
import pageMenus from '../config/page-menus';

@Injectable({
  providedIn: 'root'
})
export class MenuLoaderService {
    constructor(
        private menusProvider: MenusConfigProvider) {
    //super();
    
    console.log("MenuLoaderService");

        for (let key in this.menusProvider.config) {
            console.log('MENU: ' + key + ' -> ' + JSON.stringify(this.menusProvider.config[key]));

            let addedToMenu: boolean = false;
            console.log('pageMenus: ' + JSON.stringify(pageMenus));
            for (var m = 0; m < pageMenus.length; m++) {
                if (pageMenus[m].title == this.menusProvider.config[key].title) {
                    console.log("That's my section: " + pageMenus[m].title);
                    if (this.menusProvider.config[key].submenu != undefined) {
                        for (var sm = 0; sm < this.menusProvider.config[key].submenu.length; sm++) {
                            console.log("A : " + JSON.stringify(this.menusProvider.config[key].submenu[sm]));
                            if (pageMenus[m].submenu != undefined) {
                                pageMenus[m].submenu.push(this.menusProvider.config[key].submenu[sm]);
                            }
                        }
                    }
                    addedToMenu = true;
                }
            }
            if (!addedToMenu) {
                console.log("B : " + JSON.stringify(this.menusProvider.config[key]));
                pageMenus.push(this.menusProvider.config[key]);
            }
        }
        console.log("NEW MENUS: " + JSON.stringify(pageMenus));
    }
}
